import { useAuth } from '@frontegg/nextjs';
import * as FullStory from '@fullstory/browser';
import { useImpersonation, useZorroUIRouter } from '@zorro/shared/utils';
import { useEffect } from 'react';
import { useAnalytics } from '~/utils/hooks/clientEvents';

export default function AnalyticsDispatcher() {
  const session = useAuth();

  const isAuthenticated = session.isAuthenticated;

  const {
    impersonatedEmployeeDetails,
    isLoading: isImpersonatedEmployeeDetailsLoading,
  } = useImpersonation();
  const { sendEvent } = useAnalytics();
  const { router } = useZorroUIRouter();

  useEffect(() => {
    const userId = session.user?.sub;

    if (
      !isAuthenticated ||
      !session?.user ||
      isImpersonatedEmployeeDetailsLoading ||
      impersonatedEmployeeDetails === undefined
    ) {
      return;
    }

    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID && userId) {
      FullStory.init(
        {
          /*
           * TODO: For some reason `process.env[EnvParams.NEXT_PUBLIC_FULLSTORY_ORG_ID]!`
           * returns undefined. Changed it as below temporarily.
           */
          orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || '',
        },
        () => {
          FullStory.FullStory('setIdentity', {
            uid: userId,
            properties: {
              userId,
              permission: session?.user?.permissions,
              roles: session?.user?.roles,
              employerId: session?.user?.tenantId,
              isImpersonated: Boolean(impersonatedEmployeeDetails?.id),
            },
          });
          FullStory.FullStory('setProperties', {
            type: 'page',
            properties: {
              application: 'zorro-ui',
            },
          });
        }
      );
    }
  }, [
    impersonatedEmployeeDetails,
    isAuthenticated,
    isImpersonatedEmployeeDetailsLoading,
    session.user,
  ]);

  useEffect(() => {
    if (
      !isAuthenticated ||
      !session?.user ||
      isImpersonatedEmployeeDetailsLoading ||
      impersonatedEmployeeDetails === undefined ||
      !sendEvent
    ) {
      return;
    }

    void sendEvent('page_visit');
  }, [
    isAuthenticated,
    session?.user,
    impersonatedEmployeeDetails,
    isImpersonatedEmployeeDetailsLoading,
    sendEvent,
    router.pathname,
  ]);

  return null;
}
