import { useQuery } from '@tanstack/react-query';
import { EmployerSignupStatus, FronteggRolesDto } from '@zorro/clients';
import { EmployerLogo, LogoDark, SupportDialog } from '@zorro/shared/ui';
import { callEndpoint, nullIfNotFound } from '@zorro/shared/utils';
import { Box, Divider, Flex, Group } from '@zorro/zorro-ui-design';
import { ZorroUiUser } from '~/components/layouts/ZorroUiUser';

interface HeaderTopBarProps {
  isAuthenticated: boolean;
  employerId: string;
  userRoles: FronteggRolesDto[];
  profilePicture?: string | null;
  isImpersonated?: boolean;
}

export const HeaderTopBar = ({
  isAuthenticated,
  employerId,
  userRoles,
  profilePicture,
  isImpersonated = false,
}: HeaderTopBarProps) => {
  const { data: employerPublicInfo } = useQuery({
    queryKey: ['EmployerPublicInfo', isAuthenticated, employerId],
    queryFn: async () => {
      if (!employerId) {
        return null;
      }

      return isAuthenticated
        ? await callEndpoint({
            method: 'employersControllerFindEmployerPublicInfo',
            params: [employerId],
          })
        : await nullIfNotFound(() =>
            callEndpoint({
              method: 'healthControllerAnonymousFindEmployerPublicInfo',
              params: [employerId],
              anonymous: true,
            })
          );
    },
  });

  const { logoUrl, signupStatus } = employerPublicInfo || {};

  return (
    <Group
      data-testid="header-top-bar"
      justify="space-between"
      align="center"
      wrap="nowrap"
      h="100%"
    >
      <Box>{logoUrl && <EmployerLogo logoUrl={logoUrl} />}</Box>
      <Flex align="center" data-testid="header-top-bar-logo-box">
        <Box
          display={{
            base: isAuthenticated ? 'none' : 'inherit',
            sm: 'inherit',
          }}
        >
          <LogoDark />
        </Box>
        {isAuthenticated && (
          <>
            <Divider
              display={{ base: 'none', sm: 'inherit' }}
              className="excluded-from-print"
              orientation="vertical"
              mx={32}
              my={7}
            />
            <SupportDialog />
            <ZorroUiUser
              userRoles={userRoles}
              profilePicture={profilePicture}
              isImpersonated={isImpersonated}
              shouldShowNavigationOptions={
                signupStatus === EmployerSignupStatus.ACTIVE
              }
            />
          </>
        )}
      </Flex>
    </Group>
  );
};
