import * as FullStory from '@fullstory/browser';
import { formatDateTimeISO, getNow } from '@zorro/shared/formatters';
import { useImpersonation } from '@zorro/shared/utils';
import axios from 'axios';
import { useCallback } from 'react';

export const useAnalytics = () => {
  const { impersonatedEmployeeDetails, isLoading } = useImpersonation();

  const sendEvent = useCallback(
    (name: string, payload?: object) => {
      if (
        process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID &&
        FullStory.isInitialized()
      ) {
        FullStory.FullStory('trackEvent', {
          name,
          properties: {
            isImpersonated: Boolean(impersonatedEmployeeDetails),
            ...payload,
          },
        });
      }
      return axios.post('/api/event', {
        name,
        timestamp: formatDateTimeISO(getNow()),
        impersonatedEmployeeId: impersonatedEmployeeDetails?.id ?? null,
        ...payload,
      });
    },
    [impersonatedEmployeeDetails]
  );

  return { sendEvent: isLoading ? null : sendEvent };
};
