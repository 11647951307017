import { Roles, UserProfile, UserRoles } from '@zorro/types';

export function doesUserHaveAccess(
  user: UserProfile | undefined | null,
  allowedRoles: Roles[]
) {
  if (!user) {
    return false;
  }

  return user.roles.some((role) =>
    allowedRoles.some((allowedRole) => allowedRole.includes(role.key))
  );
}

export function doesUserSessionHaveRole(
  user: UserRoles,
  allowedRoles: Roles[]
) {
  return user.roles.some((role) =>
    allowedRoles.some((allowedRole) => allowedRole.includes(role))
  );
}
