import { useAuth } from '@frontegg/nextjs';
import { getFullName, useImpersonation } from '@zorro/shared/utils';
import { HEADER_HEIGHT } from '@zorro/types';
import { AppShell, AppShellProps } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';
import { HeaderTopBar } from '~/components/layouts/HeaderTopBar';

interface Props extends AppShellProps {
  children: ReactNode;
  shouldRemovePadding?: boolean;
}

export function DefaultLayout({
  children,
  shouldRemovePadding,
  ...props
}: Props) {
  const { isAuthenticated, user } = useAuth();
  const { impersonatedUserDetails, impersonatedEmployeeDetails } =
    useImpersonation();

  const employerId =
    impersonatedEmployeeDetails?.employerId || user?.tenantId || '';
  const paddingProps = shouldRemovePadding
    ? // eslint-disable-next-line id-length
      { pb: 0, mainProps: { p: 0, pt: HEADER_HEIGHT } }
    : {};

  return (
    <AppShell
      headerComponent={
        <HeaderTopBar
          userRoles={impersonatedUserDetails?.roles ?? user?.roles ?? []}
          profilePicture={
            impersonatedUserDetails?.profilePictureUrl ??
            user?.profilePictureUrl
          }
          isAuthenticated={isAuthenticated}
          employerId={employerId}
          isImpersonated={Boolean(impersonatedEmployeeDetails?.id)}
        />
      }
      impersonatedEmployeeFullName={
        impersonatedUserDetails
          ? getFullName(impersonatedUserDetails)
          : undefined
      }
      {...paddingProps}
      {...props}
    >
      {children}
    </AppShell>
  );
}
