import { FronteggNextJSSession } from '@frontegg/nextjs';
import { withFronteggApp } from '@frontegg/nextjs/pages';
import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CORRELATION_ID_HEADER } from '@zorro/clients';
import { LoadingOverlayProvider } from '@zorro/shared/ui';
import { useAppProgressBar } from '@zorro/shared/utils';
import { NavigationProgress, Theme } from '@zorro/zorro-ui-design';
import axios from 'axios';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AnalyticsDispatcher from '~/components/AnalyticsDispatcher';
import { DefaultLayout } from '~/components/layouts/DefaultLayout';

import '../styles/global.css';

const ReactQueryDevtoolsProduction = lazy(() =>
  // @ts-expect-error react-query-devtools
  import('@tanstack/react-query-devtools/production').then((build) => ({
    default: build.ReactQueryDevtools,
  }))
);

interface Props extends AppProps<{ session?: FronteggNextJSSession }> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: AppProps['Component'] & {
    getLayout?: (page: ReactNode) => ReactNode;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ClientSideRendering({ children }: any) {
  const [csrReady, setCsrReady] = useState(false);

  // NOTE: this will NEVER fire on Server Side, but it will (of course) run in the Browser
  useEffect(() => {
    setCsrReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This will execute only once on client side

  return csrReady ? children : null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function ZorroApp({ Component, pageProps }: Props) {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-expect-error react-query-devtools
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  // eslint-disable-next-line react/hook-use-state
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            staleTime: 60_000,
            retry: false,
          },
        },
      })
  );

  const getLayout =
    Component.getLayout ??
    ((page: ReactNode) => <DefaultLayout>{page}</DefaultLayout>);

  axios.interceptors.request.use(function (config) {
    config.headers[CORRELATION_ID_HEADER] = uuidv4();
    return config;
  });

  useAppProgressBar();

  return (
    <ClientSideRendering>
      <Head>
        <title>Zorro</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={Theme}>
          <LoadingOverlayProvider>
            <NavigationProgress />
            <Notifications position="top-right" />
            <AnalyticsDispatcher />
            {getLayout(<Component {...pageProps} />)}
          </LoadingOverlayProvider>
        </MantineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        {showDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
      </QueryClientProvider>
    </ClientSideRendering>
  );
}

export default withFronteggApp(ZorroApp, {
  hostedLoginBox: true,
  lazyLoadAdminPortal: true,
  authOptions: {
    keepSessionAlive: true,
  },
  themeOptions: {
    adminPortal: {
      pages: {
        profile: {
          fieldsProperties: {
            name: {
              appearance: 'hidden',
            },
            phoneNumber: {
              appearance: 'hidden',
            },
            address: {
              appearance: 'hidden',
            },
            jobTitle: {
              appearance: 'hidden',
            },
          },
        },
        privacy: {
          fieldsProperties: {
            loginSessions: {
              appearance: 'hidden',
            },
          },
        },
      },
    },
  },
});
